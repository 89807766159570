import React from 'react';
import get from 'lodash.get';
import { useStaticQuery, graphql } from 'gatsby';
// import useSiteProductsMenu from 'hooks/useSiteProductsMenu';
import { getCollectionUrl } from 'helpers/url';

// disabled to use global product menu
// import { category, benefit, featured } from './items';

import {
  Spacing,
  SharedStyles,
  Container,
  Filter,
  ProductList,
} from '@matchbox-mobile/kenra-storybook';
const { StPageImageTitleBar } = SharedStyles;

// function mapCollectionItem(item, slug) {
//   let { text, collection } = item;
//   return {
//     text,
//     href: getCollectionUrl(collection || 'all'),
//     isActive: slug === collection,
//   };
// }

// function menuItem(title, items, slug) {
//   return {
//     text: title,
//     isActive: items.map(c => c.collection).includes(slug),
//     items: [
//       ...items.map(item => mapCollectionItem(item, slug)),
//       {
//         text: 'View All',
//         href: getCollectionUrl('all'),
//       },
//     ],
//   };
// }

function getCategoriesFromMenu(data, slug) {
  return data.items.map(item => {
    let collections = item.items
      .map(category => category.collection)
      .filter(Boolean);

    return {
      text: item.itemsTitle,
      isActive: collections.includes(slug),
      items: item.items.map(category => {
        let { collection, title, href } = category;
        return {
          text: title,
          href: collection
            ? getCollectionUrl(collection)
            : href || getCollectionUrl('all'),
          isActive: slug === collection,
        };
      }),
    };
  });
}

function getCategoryImageUrlFromTitle(data, title) {
  let imageUrl = null;
  data.items.forEach(item => {
    item.items.map(category => {
      if (category.title === title && category.imageUrl) {
        imageUrl = category.imageUrl;
      }
    });
  });
  return imageUrl;
}

function getCategoryImageDarknessFromTitle(data, title) {
  let isDarkImage = false;
  data.items.forEach(item => {
    item.items.map(category => {
      if (category.title === title && category.isDarkBackgroundImage) {
        isDarkImage = category.isDarkBackgroundImage;
      }
    });
  });
  return isDarkImage;
}

let Collection = props => {
  let { all, products, title, slug, description } = props;

  const {
    contentfulMenu: { json },
  } = useStaticQuery(graphql`
    query {
      contentfulMenu(slug: { eq: "top" }) {
        ...ContentfulMenuFields
      }
    }
  `);

  // Get first block
  let productCategories = json[0];

  let productItems = products.map(product => {
    return {
      link: `/product/${product.handle}`,
      img: get(product, 'images.0.localFile.publicURL'),
      title: product.title,
      // text: 'body and fullness shampoo',
    };
  });

  let hrefAll = getCollectionUrl('all');

  const url = getCategoryImageUrlFromTitle(productCategories, title);
  const isDarkImage = getCategoryImageDarknessFromTitle(
    productCategories,
    title
  );

  return (
    <>
      <Spacing removeSpaceTop removeSpaceTopMobile removeSpaceBottomMobile>
        <StPageImageTitleBar url={url || null}>
          <Container style={{ maxWidth: '45%' }}>
            <h1 style={isDarkImage ? { color: '#fff' } : {}}>{title}</h1>
            {description && (
              <p style={isDarkImage ? { color: '#fff' } : {}}>{description}</p>
            )}
          </Container>
        </StPageImageTitleBar>
      </Spacing>

      <Spacing removeSpaceTop>
        <Filter
          items={[
            {
              text: 'View all',
              href: hrefAll,
              isActive: all,
            },
            ...getCategoriesFromMenu(productCategories, slug),
            // menuItem('Featured', featured, slug),
            // menuItem('Category', category, slug),
            // menuItem('Benefit', benefit, slug),
            // {
            //   text: 'Brand',
            //   items: [
            //     {
            //       text: 'Kenra',
            //     },
            //     {
            //       text: 'Kenra Platinum',
            //     },
            //     {
            //       text: 'Kenra Color',
            //       checkboxes: [
            //         {
            //           label: 'Permanent',
            //           id: 'id-checkbox-permanent',
            //         },
            //         {
            //           label: 'Demi-Permanent',
            //           id: 'id-checkbox-demi-permanent',
            //         },
            //         {
            //           label: 'Semi-Permanent',
            //           id: 'id-checkbox-semi-permanent',
            //         },
            //         {
            //           label: 'Simply Blonde',
            //           id: 'id-checkbox-simply-blonde',
            //         },
            //         {
            //           label: 'Studio Stylist Express',
            //           id: 'id-checkbox-studio-stylist-express',
            //         },
            //       ],
            //     },
            //   ],
            // },
          ]}
        />
      </Spacing>

      <Spacing removeSpaceTop removeSpaceBottom>
        <Container>
          <ProductList items={productItems} />
        </Container>
      </Spacing>
    </>
  );
};

export default Collection;
