import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Collection from 'components/Collection';

export default props => {
  let {
    data: {
      shopifyCollection: { products, title, description },
    },
    pageContext: { slug },
  } = props;

  return (
    <Layout>
      <Collection
        slug={slug}
        title={title}
        products={products}
        description={description}
      />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    shopifyCollection(handle: { eq: $slug }) {
      ...shopifyCollectionFields
    }
  }
`;
